import LogRocket from "logrocket";
import { trackGA_Event } from "../utilsMethods/trackGA";
import { User } from "../lib/user";
import { loadIntercom } from "intercom-next";

declare const heap;

let logrocketSetup = false;
let logrocketIntegrated = false;
let intercomSetup = false;
let heapSetup = false;
let heapIdentified = false;
let recordingURL = '';
const intercomId = 'k0dv8nvw';

export function logException(error: Error, properties?: { [key: string]: string }) {
  if (logrocketSetup) {
    LogRocket.captureException(error, {
      extra: properties || {},
    })
  } else {
    console.error(error, properties);
  }
}

function setupLogrocketDatadog() {
  // patch fetch
  if (typeof fetch === 'function') {
    const origFetch = window.fetch;
    function updateOptions(options) {
      const update = { ...options };
      update.headers = {
        ...update.headers,
        'X-LogRocket-URL': recordingURL,
      };
      return update;
    }
    window.fetch = function(url, options) {
      return origFetch(url, url[0] === '/' ? updateOptions(options) : options);
    }
  }
}

function updateLogrocketIntegrations(user) {
  const userInfo = {...user, name: `${user.firstname} ${user.lastname}`};
  LogRocket.identify(user._id, userInfo);

  // Integrate LogRocket with Intercom/GA/Datadog
  LogRocket.getSessionURL(sessionURL => {
    trackGA_Event('LogRocket', sessionURL, '');
    recordingURL = sessionURL;
    if (intercomSetup) {
      (window as any).intercomSettings = {
        ...(window as any).intercomSettings,
        first_name: user.firstname,
        email: user.email,
        logrocketURL: sessionURL,
      }
      try {
        (window as any).Intercom('update');
      } catch (e) {
        // no op
      }
    }
  });
}

function isDesktop() {
  return typeof window !== 'undefined' && window?.screen?.width > 1000;
}

export default (isLoggedIn: boolean | undefined, user: User | undefined) => {
  if (!intercomSetup && isLoggedIn && user && user._id) {
    loadIntercom({
      appId: intercomId,
      email: user.email,
      first_name: user.firstname,
    });
    intercomSetup = true;
  }

  if (!logrocketSetup && window?.location?.hostname !== 'localhost' && isDesktop()) {
    LogRocket.init('g7swdm/covid-reserve');
    logrocketSetup = true;
  }

  heapSetup = typeof heap !== 'undefined'
  if (!heapIdentified && user && user._id && heapSetup) {
    try {
      heap.identify(user._id);
      heap.addUserProperties(user);
      heapIdentified = true;
    } catch (e) {
      console.log('Heap error', e);
      // no op
    }
  }

  if (!logrocketIntegrated && logrocketSetup && isLoggedIn && user && user._id && window?.location?.hostname !== 'localhost' && isDesktop()) {
    const userInfo = {...user, name: `${user.firstname} ${user.lastname}`};
    LogRocket.identify(user._id, userInfo);
    setupLogrocketDatadog();
    updateLogrocketIntegrations(user);
    logrocketIntegrated = true;
  } else if (logrocketSetup && isLoggedIn && user && user._id) {
    // Re-identify user
    updateLogrocketIntegrations(user);
    logrocketIntegrated = true;
  }
}
