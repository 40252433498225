import React from 'react';
import { NextPage } from 'next';
import Link from 'next/link';
import { useRouter } from 'next/router';

type Props = {
  href: string;
  as?: string;
}

/**
 * Same as next/link/Link except it preserves query params across routes
 * Useful for keeping the UTM tags across static pages
 */
const QueryPreservingLink: NextPage<Props> = ({ href, children, as }) => {
  const router = useRouter();
  if (Object.keys(router.query).some(v => v.indexOf('utm_') !== -1)) {
    return <Link href={{ pathname: href, query: router.query }} as={as ? { pathname: as, query: router.query } : undefined}>{children}</Link>
  }
  return <Link href={href} as={as}>{children}</Link>
}

export { QueryPreservingLink };
