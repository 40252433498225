import { generateId } from './generateId';
import * as qs from 'querystring';

export const generateGuid = (store = true) => {
  const guid = localStorage.getItem('guid');
  if (guid && guid !== '' && guid !== undefined) {
    return JSON.parse(guid);
  }
  const queryString = window.location.search;
  const paramsBody = {
    utm_campaign: '',
    utm_referrer: '',
    utm_medium: '',
    utm_partner: '',
    guid: generateId() + Date.now(),
  };
  if (queryString && queryString !== '') {
    const urlParams = qs.parse(queryString?.substr(1));
    if (urlParams) {
      const utm_campaign = urlParams['utm_campaign'] ?? '';
      const utm_referrer = urlParams['utm_referrer'] ?? '';
      const utm_medium = urlParams['utm_medium'] ?? '';
      const utm_partner = urlParams['utm_partner'] ?? '';

      paramsBody.utm_campaign = String(utm_campaign) ?? '';
      paramsBody.utm_referrer = String(utm_referrer) ?? '';
      paramsBody.utm_medium = String(utm_medium) ?? '';
      paramsBody.utm_partner = String(utm_partner) ?? '';
    }
  }
  console.log('utm', paramsBody);
  if (store) {
    localStorage.setItem('guid', JSON.stringify(paramsBody));
  }
  return paramsBody;
};
