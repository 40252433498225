import React from 'react';
import { NextPage } from 'next';
import Link from 'next/link';
import { useRouter } from 'next/router';

type Props = {
  href: string | any;
  style?: any
  as?: string;
}

/**
 * Same as next/link/Link except it adds a "selected"
 * class when the current route is the link's href.
 */

const RouterLink: NextPage<Props> = ({ href, children, style, as }) => {
  if (React.isValidElement(children)) {
    const router = useRouter();
    // look at first 2 levels only
    const first2Href = (href.pathname || href).split("/").slice(1, 3).join("/").split("?")[0];
    const first2Path = router.pathname.split("/").slice(1, 3).join("/").split("?")[0];
    const className =
      (first2Path === first2Href) ?
        `${children.props.className || ''} selected` :
        children.props.className;

    return <Link href={href} as={as}>{React.cloneElement(children, { className })}</Link>
  } else {
    return null;
  }
}

export { RouterLink };
