import { fst, snd } from 'fp-ts/lib/Tuple';
import Link from 'next/link';
import { RouterLink } from './routerLink';
import { NextPage } from 'next';
import { UserIcon } from '../components/Icons';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';

import { User } from '../lib/user';
import { Button } from '@material-ui/core';
import { trackGA_Event } from '../utilsMethods/trackGA';
import { QueryPreservingLink } from './QueryPreservingLink';
import setupTracking from '../lib/setupTracking';

type Props = {
  siteMap?: Array<[string, string]>;
  isLoggedIn?: boolean;
  user?: User & { _id: string };
  transparent?: boolean;
  onLogOut: (event: any) => void;
};

let isSetup = false;

const buttonStyle: React.CSSProperties = { display: 'flex', padding: '8px', fontFamily: 'Inter', textTransform: 'none', fontSize: '16px', fontWeight: 400 };

const Nav: NextPage<Props> = ({
  siteMap,
  isLoggedIn,
  user,
  onLogOut,
  transparent,
}) => {
  useEffect(() => {
    setupTracking(isLoggedIn, user);
  }, [isLoggedIn]);

  const router = useRouter();

  const formPage = router.pathname.indexOf("/form/") === 0 && router.pathname.indexOf("/additional-documents") === -1 ? true : false;

  const menu = (
    <>
      {
    (isLoggedIn === undefined) ?
      <div style={{ height: '44px' }} />
    : (isLoggedIn === false) ?
      <div />
    :
      <>
        <Button style={buttonStyle} onClick={(e) => {
          Swal.fire({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            icon: 'info',
            title: 'Logging out',
            showClass: {
              popup: 'swal2-noanimation',
              backdrop: 'swal2-noanimation'
            },
          });
          onLogOut(e);
        }}>
          <div style={{ marginRight: '8px' }}>{UserIcon}</div>
          Logout
        </Button>
      </>
  }
    </>
  );
  return (
    <div>
      <nav className='navbar navbar-expand-lg navbar-light'>
      <QueryPreservingLink href='/'>
        <a style={{ maxWidth: '260px' }}>
          <div className={'logo navbar-brand'}>
            <img height="48px" width="auto" src={require('../public/img/pppays-logo-black.svg')}/>
          </div>
        </a>
      </QueryPreservingLink>
      <button
        className='navbar-toggler'
        type='button'
        data-toggle='collapse'
        data-target='#navbarSupportedContent'
        aria-controls='navbarSupportedContent'
        aria-expanded='false'
        aria-label='Toggle navigation'
      >
       <div id="nav-icon1">
        <span></span>
        <span></span>
        <span></span>
      </div>
      </button>

      <div
        className='menu collapse navbar-collapse'
        id='navbarSupportedContent'
      >
        <div className='navbar-nav ml-auto'>{menu}</div>
      </div>

    </nav>
    {formPage && <div className='lower'>
        {siteMap!.map((item, i) => {
          return (
            <RouterLink href={snd(item)} as={(item as any)[2]} key={i}>
              <a>
                {fst(item)}
              </a>
            </RouterLink>
          );
        })}
      </div>}
      <style jsx>
        {`
          nav.mynavblue: {
            background: #0085ff !important;
          }
          nav div.menu a {
            font-size: 20px;
            margin: 0 20px;
          }
          nav div.menu {
            float: right;
          }
          nav div.logo {
            font-size: 33px;
            margin: 0;
            font-weight: 700;
            height: 60px;
            display: flex;
            align-items: center;
          }
          div.lower {
            height: 53px;
            line-height: 53px;
            font-size: 20px;
            margin: 24px 12px 0;
          }
          div.lower :global(a) {
            margin-left: 24px;
            border-radius: 5px;
            font-size: 20px;
            font-family: 'Inter';
            font-weight: 300;
            padding: 8px;
            background: #B1B9C0;
            color: #1F2433;
          }

          div.lower :global(a.selected) {
            background: #DF1E2E;
            color: white !important;
          }
          div.lower :global(a),
          div.lower :global(a:visited),
          div.lower :global(a:hover) {
            color: #1F2933;
          }
          a.button, a.button:visited, a.button:active {
            display: inline;
            background: #1382E9;
            border-radius: 5px;
            padding: 12px 16px;
            font-size: 20px;
            color: white !important;
           }
           @media (max-width: 567px) {
             nav div.logo {
              font-size: 25px;
            }
          }
          #nav-icon1 {
            width: 36px;
            height: 4px;
            position: relative;
            margin: 7px 0 22px 0;
            /* transition: .5s ease-in-out; */
            cursor: pointer;
          }
          /*Navbar css*/
          @media (min-width: 992px) {
            .navbar-expand-lg {
              flex-flow: row nowrap;
              justify-content: flex-start;
            }
            .navbar-expand-lg .navbar-toggler {
              display: none;
            }
            .navbar-expand-lg .navbar-collapse {
              display: flex !important;
              flex-basis: auto;
            }
            .navbar-expand-lg .navbar-nav {
              flex-direction: row;
              margin: 0;
            }
            .navbar-collapse a {
              padding-right: 34px;
              color: #000;
              font-size: 20px;
            }
            .navbar-expand-lg .navbar-nav .nav-link {
              padding-right: 0.5rem;
              padding-left: 0.5rem;
            }
          }

          .navbar {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            padding: 8px 16px;
          }
          .navbar-nav {
            display: flex;
            flex-direction: column;
            padding-left: 0;
            margin-bottom: 0;
            list-style: none;
          }
          .ml-auto, .mx-auto {
              margin-left: auto !important;
          }
          .navbar-light {
            background-color: white;
          }
          .navbar-light .navbar-toggler {
            color: rgba(0, 0, 0, 0.5);
            border-color: rgba(0, 0, 0, 0.1);
          }
          .navbar-toggler {
            padding: 0.25rem 0.75rem;
            font-size: 1.25rem;
            line-height: 1;
            background-color: transparent;
            border: 1px solid transparent;
            border-radius: 0.25rem;
          }
          .navbar-light .navbar-toggler-icon {
            background-image: url("data:image/svg+xml;utf8, <svg xmlns='http://www.w3.org/2000/svg' width='30' hei…miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
          }
          .navbar-toggler-icon {
            display: inline-block;
            width: 1.5em;
            height: 1.5em;
            vertical-align: middle;
            content: "";
            background: no-repeat center center;
            background-size: 100% 100%;
          }
          .navbar-collapse {
            flex-basis: 100%;
            flex-grow: 1;
            align-items: center;
          }
          .navbar-light .navbar-nav .show > .nav-link, .navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .nav-link.active {
            color: rgba(0, 0, 0, 0.9);
          }
          .navbar-light .navbar-nav .nav-link {
            color: rgba(0, 0, 0, 0.5);
          }
          .nav-link {
            display: block;
            padding: 0.5rem 1rem;
          }
          .collapse:not(.show) {
            display: none;
          }

          #nav-icon1 span {
            display: block;
            position: absolute;
            height: 4px;
            width: 100%;
            background: #a6a6a6;
            border-radius: 9px;
            opacity: 1;
            left: 0;
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: .25s ease-in-out;
            -moz-transition: .25s ease-in-out;
            -o-transition: .25s ease-in-out;
            transition: .25s ease-in-out;
          }

          #nav-icon1 span:nth-child(1) {
            top: 0px;
          }

          #nav-icon1 span:nth-child(2) {
            top: 9px;
          }

          #nav-icon1 span:nth-child(3) {
            top: 18px;
          }
        `}
      </style>
    </div>
  );
};

export { Nav };
