import ReactGA from 'react-ga';
import {generateGuid} from './getGAGuid';


export const trackGA_PageView = (page) => {
  ReactGA.initialize('UA-171540505-1');
  ReactGA.pageview(page);
}

export const trackGA_Event = (category, action, label) => {
  let paramsBody =  generateGuid(false);
  ReactGA.initialize('UA-171540505-1');
  ReactGA.event({
        category: category,
        action: action,
        // Sample : apply_now_distributor|anheuser_busch|email|distributor|Sign Up
        label: `${paramsBody.utm_campaign}|${paramsBody.utm_referrer}|${paramsBody.utm_medium}|${paramsBody.utm_partner}|${label}`
    });
}
